* {
    color: black;
}

.ui.container {
    height: 120vh !important;
}

#exec-grid {
    width: 100%;
}

#exec-title {
    font-size: 7vh;
    font-weight: 900;
}

.board-member {
    display: flex !important;
    justify-content: center !important;
}

.headshot {
    width: 15.5vw;
    height: 23.5vh;
    object-fit: cover;
}


.member-card {
    max-width: 15.5vw !important;
    height: 100%;
}

.member-name {
    text-align: center;
}

.grid-row {
    width: 100%;
    display: flex;
    justify-content: center;
}

#new-member {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    padding: 1em;
    text-align: center;
    background-color: #cfcece;
}

#new-member-link {
    color: black;
    font-weight: bold;
}

.ui.grid>.row>.column {
    margin-top: 12px;
    margin-bottom: 10px;
}

.ui.card:last-child {
    margin-bottom: 16px !important;
}

@media (max-width: 991px) {

    #exec-grid {
        width: 100%;
    }
    .ui.grid>.row{
        display: block !important;
    }

    .ui.grid>.row>.column {
        margin-top: 12px;
        margin-bottom: 10px;
    }

    .ui.card:last-child {
        margin-bottom: 16px;
    }

    .ui.grid > [class*="four column"].row > .column {
        width: 100% !important;
    }

    .headshot {
        width: 100%;
        height: 50vw;
    }

    .member-card {
        min-width: 100% !important;
    }
}

