@import url(/cdn.jsdelivr.net/npm/hack-font@3.3.0/build/web/hack.css);
@import url(https://fonts.googleapis.com/css?family=Lato:900&display=swap);

h1 {
    font-weight: 900;
}

span {
    font-weight: 900;
}


#home-text {
    /* margin-top: 2vw; */
    width: 86%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-image: url("/src/img/code.jpg");
}

#welcome-to {
    color: white;
    font-size: 6.5vw;
    line-height: 6.5vw;
}

#loyola {
    color: #a40047;
    font-size: 6.5vw;
    line-height: 6.5vw;
}

#university {
    color: #e7af12;
    font-size: 6.5vw;
    line-height: 6.5vw;
}

#chicago-coding {
    line-height: 6.5vw;
}

#chicago {
    color: #a40047;
    font-size: 6.5vw;
    line-height: 6.5vw;
}

#coding {
    color: white;
    font-family: Hack, monospace;
    font-size: 7vw;
    font-weight: 100;
    float: right;
   
}

#community {
    color: white;
    font-family: Hack, monospace;
    font-size: 7vw;
    font-weight: 100;
    line-height: 4vw;
    
    float: right;
}

@media (max-width: 991px) {


    #mobile-home-div {
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    #home-text {
        margin-top: 5vw;
        width: auto;
        height: 44% !important;
        display: flex;
        justify-content: start;
        overflow: hidden;
    }

    #welcome-to {
        font-size: 10vw;
        line-height: 10vw;
    }

    #loyola {
        font-size: 12vw;
        line-height: 12vw;
    }

    #university {
        font-size: 12vw;
        line-height: 12vw;
    }

    #chicago {
        font-size: 12vw;
        line-height: 12vw;
    }

    #coding {
        float: left;
        font-size: 13vw;
        line-height: 13vw;
        margin-left: 0vw;
    }

    #community {
        float: left;
        font-size: 13vw;
        line-height: 13vw;
    }
}
* {
    color: black;
}

#events-grid {
    width: 100%;
}

#events-title {
    font-size: 7vh;
    font-weight: 900;
}

#list-column {
    padding-top: 1.5vh;
    padding-left: 4vw;
    text-align: right;
    float: right;
    height: 62.5vh;
    overflow-y: scroll;
    font-size: 1.5em;
}

@media (max-width: 991px) {
    #events-title {
        float: left;
    }

    .mobile-list {
        font-size: 1.25em;
    }
}
* {
    color: black;
}

.ui.container {
    height: 120vh !important;
}

#exec-grid {
    width: 100%;
}

#exec-title {
    font-size: 7vh;
    font-weight: 900;
}

.board-member {
    display: flex !important;
    justify-content: center !important;
}

.headshot {
    width: 15.5vw;
    height: 23.5vh;
    object-fit: cover;
}


.member-card {
    max-width: 15.5vw !important;
    height: 100%;
}

.member-name {
    text-align: center;
}

.grid-row {
    width: 100%;
    display: flex;
    justify-content: center;
}

#new-member {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    padding: 1em;
    text-align: center;
    background-color: #cfcece;
}

#new-member-link {
    color: black;
    font-weight: bold;
}

.ui.grid>.row>.column {
    margin-top: 12px;
    margin-bottom: 10px;
}

.ui.card:last-child {
    margin-bottom: 16px !important;
}

@media (max-width: 991px) {

    #exec-grid {
        width: 100%;
    }
    .ui.grid>.row{
        display: block !important;
    }

    .ui.grid>.row>.column {
        margin-top: 12px;
        margin-bottom: 10px;
    }

    .ui.card:last-child {
        margin-bottom: 16px;
    }

    .ui.grid > [class*="four column"].row > .column {
        width: 100% !important;
    }

    .headshot {
        width: 100%;
        height: 50vw;
    }

    .member-card {
        min-width: 100% !important;
    }
}


* {
    color: black;
}

.ui form{
    height: 100%;
}

.ui.container {
    height: 100% !important;
    width: 150vh !important;
}

#contact-grid {
    width: 100%;
    margin-top: 3vh;
    height: 250vh;
}


#slack-logo {
    width: 1.75em;
    height: 1.75em;
    margin-right: .25em;
    cursor: pointer;
}

#join-slack-link {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 0.25em;
}

#image-column {
    display: flex;
    justify-content: center;
}

#form {
    height: 100vh;
}

#form-column {
    padding-left: 4vw; 
    height: 100vh;
}

#contact-title {
    font-size: 7vh;
    font-weight: 900;
    float: right;
    line-height: 4vh;
}

#contact-text {
    margin-top: .5em;
    font-size: 1.5em;
    text-align: right;
    margin-bottom: 1.5em;
    line-height: 1.5em;
}

#cs-award {
    width: 65%;
    height: 35%;
    object-fit: cover;
}

#submit {
    width: 100%;
    margin-top: 2vh;
}

@media (max-width: 991px) {

    #join-slack-link {
        display: inline-flex;
        align-items: center;
        float: left;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    #contact-title {
        float: left;
        line-height: normal;
        
        font-size: xx-large;
    }

    #contact-grid {
        margin-top: 0vh;
        height: 160vh;
        
    }
    
    #contact-text {
        /* float: left; */
        text-align: center;
        width: 100%;
        margin-bottom: 1.5em;
    }

    #cs-award {
        width: 33%;
        height: 99%;
        object-fit: cover;
    }

    #image-wrapper {
        width: 100%;
        height: 45vh;
        object-fit: cover;
        display: flex;
        justify-content: center;
    }

    #form {
        display: block;
        margin: 0 auto;
        text-align: center;
        width: 95%;
        padding: 0.1rem;
    }
}

#nav-bar {
    margin-top: 2vh;
    margin-bottom: 4vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#logo {
    height: 12.5vh;
    width: auto;
}

.nav-item {
    color: rgb(241, 241, 241);
    font-size: 1.5em;
}

.nav-item:hover {
    color: rgb(255, 255, 255);;
}

@media (max-width: 991px) {
    #mobile-nav {
        height: 20vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        width: 100%;
    }

    #logo {
        height: 15vw;
        width: auto;
    }

    /* Position and sizing of burger button */
.bm-burger-button {
    position: relative;
    width: 10vw;
    height: 8vw;
  }

  .bm-burger-button:hover{
    color: #fafafa;;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #fafafa;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 10vw !important;
    width: 10vw !important;
    margin-right: 4vw;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #ffffff;
    height: 7.5vw !important;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100vh !important;
    width: 100vw !important;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: rgb(0, 0, 0);
    padding: 1.5em 1.5em 0;
    font-size: 1.15em;
    margin-top: -5vw;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  /* Individual item */
  .bm-item {
    padding: 5vw;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}
* {
    color: black;
}

#about-grid {
    width: 100%;
}

#about-div {
}

#about-title {
    font-size: 7vh;
    font-weight: 900;
}

#text-column {
    padding-right: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-text {
    font-size: 1.5em;
}

.about-text-mobile {
    font-size: 1.25em;
}

/* @media (min-width: 992px) and (max-width: 1199px) {
    .about-text {
        font-size: 1.5em;
    }
} */
#switch {
    /* width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center; */
}

body {
    background:black;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    min-width: 320px;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    font-size: 14px;
    line-height: 1.8285em;
    height: 100%;
}

html {
    height: 100%;
}

.ui.grid {
    height: 100%;
}

*{
    color: white;
}
