* {
    color: black;
}

#events-grid {
    width: 100%;
}

#events-title {
    font-size: 7vh;
    font-weight: 900;
}

#list-column {
    padding-top: 1.5vh;
    padding-left: 4vw;
    text-align: right;
    float: right;
    height: 62.5vh;
    overflow-y: scroll;
    font-size: 1.5em;
}

@media (max-width: 991px) {
    #events-title {
        float: left;
    }

    .mobile-list {
        font-size: 1.25em;
    }
}