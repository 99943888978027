
@import url('/cdn.jsdelivr.net/npm/hack-font@3.3.0/build/web/hack.css');

h1 {
    font-weight: 900;
}

span {
    font-weight: 900;
}


#home-text {
    /* margin-top: 2vw; */
    width: 86%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-image: url("/src/img/code.jpg");
}

#welcome-to {
    color: white;
    font-size: 6.5vw;
    line-height: 6.5vw;
}

#loyola {
    color: #a40047;
    font-size: 6.5vw;
    line-height: 6.5vw;
}

#university {
    color: #e7af12;
    font-size: 6.5vw;
    line-height: 6.5vw;
}

#chicago-coding {
    line-height: 6.5vw;
}

#chicago {
    color: #a40047;
    font-size: 6.5vw;
    line-height: 6.5vw;
}

#coding {
    color: white;
    font-family: Hack, monospace;
    font-size: 7vw;
    font-weight: 100;
    float: right;
   
}

#community {
    color: white;
    font-family: Hack, monospace;
    font-size: 7vw;
    font-weight: 100;
    line-height: 4vw;
    
    float: right;
}

@media (max-width: 991px) {


    #mobile-home-div {
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    #home-text {
        margin-top: 5vw;
        width: auto;
        height: 44% !important;
        display: flex;
        justify-content: start;
        overflow: hidden;
    }

    #welcome-to {
        font-size: 10vw;
        line-height: 10vw;
    }

    #loyola {
        font-size: 12vw;
        line-height: 12vw;
    }

    #university {
        font-size: 12vw;
        line-height: 12vw;
    }

    #chicago {
        font-size: 12vw;
        line-height: 12vw;
    }

    #coding {
        float: left;
        font-size: 13vw;
        line-height: 13vw;
        margin-left: 0vw;
    }

    #community {
        float: left;
        font-size: 13vw;
        line-height: 13vw;
    }
}