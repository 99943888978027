* {
    color: black;
}

#about-grid {
    width: 100%;
}

#about-div {
}

#about-title {
    font-size: 7vh;
    font-weight: 900;
}

#text-column {
    padding-right: 4vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.about-text {
    font-size: 1.5em;
}

.about-text-mobile {
    font-size: 1.25em;
}

/* @media (min-width: 992px) and (max-width: 1199px) {
    .about-text {
        font-size: 1.5em;
    }
} */