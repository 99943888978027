
body {
    background:black;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    min-width: 320px;
    font-family: "SF Pro Text","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
    font-size: 14px;
    line-height: 1.8285em;
    height: 100%;
}

html {
    height: 100%;
}

.ui.grid {
    height: 100%;
}

*{
    color: white;
}