* {
    color: black;
}

.ui form{
    height: 100%;
}

.ui.container {
    height: 100% !important;
    width: 150vh !important;
}

#contact-grid {
    width: 100%;
    margin-top: 3vh;
    height: 250vh;
}


#slack-logo {
    width: 1.75em;
    height: 1.75em;
    margin-right: .25em;
    cursor: pointer;
}

#join-slack-link {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 0.25em;
}

#image-column {
    display: flex;
    justify-content: center;
}

#form {
    height: 100vh;
}

#form-column {
    padding-left: 4vw; 
    height: 100vh;
}

#contact-title {
    font-size: 7vh;
    font-weight: 900;
    float: right;
    line-height: 4vh;
}

#contact-text {
    margin-top: .5em;
    font-size: 1.5em;
    text-align: right;
    margin-bottom: 1.5em;
    line-height: 1.5em;
}

#cs-award {
    width: 65%;
    height: 35%;
    object-fit: cover;
}

#submit {
    width: 100%;
    margin-top: 2vh;
}

@media (max-width: 991px) {

    #join-slack-link {
        display: inline-flex;
        align-items: center;
        float: left;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    #contact-title {
        float: left;
        line-height: normal;
        
        font-size: xx-large;
    }

    #contact-grid {
        margin-top: 0vh;
        height: 160vh;
        
    }
    
    #contact-text {
        /* float: left; */
        text-align: center;
        width: 100%;
        margin-bottom: 1.5em;
    }

    #cs-award {
        width: 33%;
        height: 99%;
        object-fit: cover;
    }

    #image-wrapper {
        width: 100%;
        height: 45vh;
        object-fit: cover;
        display: flex;
        justify-content: center;
    }

    #form {
        display: block;
        margin: 0 auto;
        text-align: center;
        width: 95%;
        padding: 0.1rem;
    }
}