
#nav-bar {
    margin-top: 2vh;
    margin-bottom: 4vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#logo {
    height: 12.5vh;
    width: auto;
}

.nav-item {
    color: rgb(241, 241, 241);
    font-size: 1.5em;
}

.nav-item:hover {
    color: rgb(255, 255, 255);;
}

@media (max-width: 991px) {
    #mobile-nav {
        height: 20vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        width: 100%;
    }

    #logo {
        height: 15vw;
        width: auto;
    }

    /* Position and sizing of burger button */
.bm-burger-button {
    position: relative;
    width: 10vw;
    height: 8vw;
  }

  .bm-burger-button:hover{
    color: #fafafa;;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: white;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #fafafa;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 10vw !important;
    width: 10vw !important;
    margin-right: 4vw;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #ffffff;
    height: 7.5vw !important;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100vh !important;
    width: 100vw !important;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: rgb(0, 0, 0);
    padding: 1.5em 1.5em 0;
    font-size: 1.15em;
    margin-top: -5vw;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
  
  /* Individual item */
  .bm-item {
    padding: 5vw;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
}